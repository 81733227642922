import _toConsumableArray from "/builds/u5yGSXwH/11/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/builds/u5yGSXwH/11/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRouter } from 'next/router';
import { sessionStorage } from 'react-storage';
import { forEach, groupBy } from 'lodash';
import { GTMEventCategory, GTMEventTypes, EventValues } from '@sial/common-utils';
import { Dimension91, RemoveFromCartEnum, AddToCartPagesEnum, ExtendedCategoryValues, ExtendedEventValues, AffiliationCheckoutEventActionEnum } from '@utils/analytics/enums';
import { showGTMTag, isTealium, sendEvent, legacySendEvent, getCurrencyCode, regexForStripEncodingUnicode, regexForStripHTML, DEFAULT_CURRENCY_CODE, CART_DATA_STORAGE_KEY, ADD_TO_CART_GA_DATA_STORAGE_KEY, regexForStripSymbols, getCurrencyCodeFromProduct, setMaterialData, setPaClipData, mapPaymentMethod, clubbingUpProducts, uaConfiguratorAddToCart, regexForStripSpecialChars, priceFormatter } from '@utils/analytics/coreAnalytics';
import { sendAddItemsToCartEvent, sendTealiumCartDataEvent, mapProductErpType, getErpTypeParam } from '../tealiumAnalytics';
import { calculateDiscountPercentage } from '@utils/analytics/analyticsUtils';
import { ConfiguratorProductType } from '@utils/analytics/configuratorAnalytics';
import { CartItemType } from '@src/types/graphql-types';
export var GTMMarketplaceEventCategory = _objectSpread(_objectSpread({}, GTMEventCategory), ExtendedCategoryValues);
export var GTMMarketplaceEventValues = _objectSpread(_objectSpread({}, EventValues), ExtendedEventValues);
export var sendAddToCartAnalyticsEvent = function sendAddToCartAnalyticsEvent(gtmFlag, overrides) {
  if (isTealium && !gtmFlag) {
    sendAddItemsToCartEvent({
      sessionStorageKey: ADD_TO_CART_GA_DATA_STORAGE_KEY
    });
  }

  if (showGTMTag) {
    try {
      var _ref = sessionStorage.getItem(ADD_TO_CART_GA_DATA_STORAGE_KEY),
          products = _ref.products,
          pageName = _ref.pageName,
          currency = _ref.currency;

      if (products && products.length) {
        // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
        //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
        legacySendEvent({
          eventType: GTMEventTypes.AddToCart,
          payload: {
            eventCategory: (overrides === null || overrides === void 0 ? void 0 : overrides.category) || GTMEventCategory.AddToCart.toLowerCase(),
            eventAction: (overrides === null || overrides === void 0 ? void 0 : overrides.action) || pageName,
            eventLabel: (overrides === null || overrides === void 0 ? void 0 : overrides.label) || (products === null || products === void 0 ? void 0 : products.map(function (item) {
              return item === null || item === void 0 ? void 0 : item.id;
            }).join('|')),
            eventInteractionType: 0,
            ecommerce: {
              currencyCode: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
              add: {
                actionField: {
                  list: pageName.toLowerCase()
                },
                products: products
              }
            }
          }
        }); // GA4 - Add To Cart

        legacySendEvent({
          payload: {
            event: 'add_to_cart',
            addtocart_type: 'default',
            ecommerce: {
              currency: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
              value: products.reduce(function (acc, product) {
                var _product$quantity;

                return (Number(product.price) || 1) * ((_product$quantity = product.quantity) !== null && _product$quantity !== void 0 ? _product$quantity : 1) + acc;
              }, 0),
              items: products.map(function (product) {
                var _product$discount, _product$quantity2;

                return {
                  item_id: product.id,
                  item_name: product.name,
                  discount: (_product$discount = product.discount) !== null && _product$discount !== void 0 ? _product$discount : 0,
                  item_brand: product.brand,
                  item_list_id: pageName === null || pageName === void 0 ? void 0 : pageName.toLowerCase(),
                  item_list_name: pageName === null || pageName === void 0 ? void 0 : pageName.toLowerCase(),
                  item_variant: product.variant,
                  price: priceFormatter(product === null || product === void 0 ? void 0 : product.price),
                  item_type: product.dimension91,
                  quantity: (_product$quantity2 = product.quantity) !== null && _product$quantity2 !== void 0 ? _product$quantity2 : 1
                };
              })
            }
          }
        });
      }

      sessionStorage.removeItem(ADD_TO_CART_GA_DATA_STORAGE_KEY);
    } catch (error) {
      sessionStorage.removeItem(ADD_TO_CART_GA_DATA_STORAGE_KEY);
      console.error('ERROR - Add to cart Event ', error);
    }
  }
};
export var sendBuyItNowAddToCartEvent = function sendBuyItNowAddToCartEvent(_ref2) {
  var currency = _ref2.currency,
      price = _ref2.price,
      quantity = _ref2.quantity,
      itemId = _ref2.itemId,
      itemName = _ref2.itemName,
      discount = _ref2.discount,
      itemBrand = _ref2.itemBrand,
      itemListId = _ref2.itemListId,
      itemListName = _ref2.itemListName,
      itemVariant = _ref2.itemVariant,
      isMarketplace = _ref2.isMarketplace;
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'buy it now',
      ecommerce: {
        currency: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
        value: (price !== null && price !== void 0 ? price : 0) * (quantity !== null && quantity !== void 0 ? quantity : 1),
        items: [{
          item_id: itemId === null || itemId === void 0 ? void 0 : itemId.toLowerCase(),
          item_name: itemName === null || itemName === void 0 ? void 0 : itemName.replace(regexForStripSymbols, '').replace(regexForStripHTML, '').replace(regexForStripEncodingUnicode, '').toLowerCase(),
          discount: discount || 0,
          item_list_id: itemListId,
          item_list_name: itemListName,
          item_brand: itemBrand === null || itemBrand === void 0 ? void 0 : itemBrand.toLowerCase(),
          item_variant: itemVariant === null || itemVariant === void 0 ? void 0 : itemVariant.toLowerCase(),
          item_type: isMarketplace ? 'marketplace' : 'standard',
          price: priceFormatter(price),
          quantity: quantity
        }]
      },
      eventCategory: 'buy it now',
      eventAction: 'buy now',
      eventLabel: itemVariant,
      eventInteractionType: 0
    }
  });
};
export var setSavedCartData = function setSavedCartData(items, pageName, userType, cartType) {
  try {
    var _items$, _items$2;

    var itemGroups = groupBy(items || [], function (item) {
      return item.materialId;
    });
    var currency = items !== null && items !== void 0 && items.length && (_items$ = items[0]) !== null && _items$ !== void 0 && _items$.currency ? (_items$2 = items[0]) === null || _items$2 === void 0 ? void 0 : _items$2.currency : DEFAULT_CURRENCY_CODE;
    var products = Object.values(itemGroups).map(function (group) {
      var _ref3, _ref3$replace, _ref3$replace$replace, _ref3$replace$replace2;

      var item = group[0];
      var avgGroupPrice = group.reduce(function (acc, item) {
        return ((item === null || item === void 0 ? void 0 : item.price) || 0) + acc;
      }, 0) / group.length;
      return _objectSpread({
        id: ((item === null || item === void 0 ? void 0 : item.product) || (item === null || item === void 0 ? void 0 : item.materialId) || EventValues.Empty).toLowerCase(),
        name: ((_ref3 = (item === null || item === void 0 ? void 0 : item.materialDescription) || (item === null || item === void 0 ? void 0 : item.materialName)) === null || _ref3 === void 0 ? void 0 : (_ref3$replace = _ref3.replace(regexForStripEncodingUnicode, '')) === null || _ref3$replace === void 0 ? void 0 : (_ref3$replace$replace = _ref3$replace.replace(regexForStripHTML, '')) === null || _ref3$replace$replace === void 0 ? void 0 : (_ref3$replace$replace2 = _ref3$replace$replace.replace(regexForStripSymbols, '')) === null || _ref3$replace$replace2 === void 0 ? void 0 : _ref3$replace$replace2.toLowerCase()) || EventValues.Empty,
        dimension91: cartType === 'marketplacecart' ? 'marketplace' : 'standard',
        variant: ((item === null || item === void 0 ? void 0 : item.materialId) || EventValues.Empty).toLowerCase(),
        brand: ((item === null || item === void 0 ? void 0 : item.brand) || EventValues.Empty).toLowerCase(),
        quantity: group.reduce(function (acc, item) {
          return acc + item.quantity;
        }, 0) || 1,
        price: priceFormatter(avgGroupPrice)
      }, !showGTMTag && {
        erpType: mapProductErpType((item === null || item === void 0 ? void 0 : item.erpType) || [], userType, pageName)
      });
    });
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products: products,
      pageName: pageName,
      currency: currency
    });
    sendAddToCartAnalyticsEvent();
    return products ? products : [];
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
    return [];
  }
};
export var sendRemoveFromCartEvent = function sendRemoveFromCartEvent(_ref4) {
  var productIds = _ref4.productIds,
      actionTitle = _ref4.actionTitle,
      ecommerce = _ref4.ecommerce;
  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    eventType: GTMEventTypes.RemoveFromCart.toLowerCase(),
    payload: {
      eventCategory: GTMEventCategory.RemoveFromCart.toLowerCase(),
      eventAction: actionTitle,
      eventLabel: productIds,
      eventInteractionType: 0,
      ecommerce: ecommerce
    }
  });
};
export var handleRemoveItemsForGA = function handleRemoveItemsForGA(payload, marketplaceCartUrlType) {
  var deletedItems = [];
  Object.keys(payload || {}).forEach(function (id) {
    var cartItem = payload[id];

    if (cartItem.markedForDelete) {
      deletedItems.push(cartItem);
    }
  });

  if (deletedItems.length) {
    var _deletedItems$, _deletedItems$2;

    var itemGroups = groupBy(deletedItems || [], function (item) {
      return item.productType === EventValues.Emprove ? item === null || item === void 0 ? void 0 : item.dossierMaterialNumber : item.materialNumber;
    });
    var gaPayload = {
      productIds: Object.values(itemGroups).map(function (product) {
        var _product$, _product$$productId;

        return (_product$ = product[0]) === null || _product$ === void 0 ? void 0 : (_product$$productId = _product$.productId) === null || _product$$productId === void 0 ? void 0 : _product$$productId.toLowerCase();
      }).join('|') || EventValues.Empty,
      actionTitle: EventValues.ShoppingCart.toLowerCase(),
      ecommerce: {
        currencyCode: getCurrencyCode(((_deletedItems$ = deletedItems[0]) === null || _deletedItems$ === void 0 ? void 0 : _deletedItems$.currency) || DEFAULT_CURRENCY_CODE),
        //default value should be USD in case of null.
        remove: {
          actionField: {
            list: EventValues.ShoppingCart.toLowerCase()
          },
          products: Object.values(itemGroups).map(function (group) {
            var _item$productId, _item$name, _item$name$replace, _item$name$replace$re, _ref5, _item$brand;

            var item = group[0];
            var avgGroupPrice = group.reduce(function (acc, item) {
              return (item.price || 0) + acc;
            }, 0) / group.length;
            return {
              id: (item === null || item === void 0 ? void 0 : (_item$productId = item.productId) === null || _item$productId === void 0 ? void 0 : _item$productId.toLowerCase()) || EventValues.Empty,
              name: (item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : (_item$name$replace = _item$name.replace(regexForStripEncodingUnicode, '')) === null || _item$name$replace === void 0 ? void 0 : (_item$name$replace$re = _item$name$replace.replace(regexForStripHTML, '')) === null || _item$name$replace$re === void 0 ? void 0 : _item$name$replace$re.toLowerCase()) || EventValues.Empty,
              variant: ((_ref5 = item.productType === EventValues.Emprove ? item === null || item === void 0 ? void 0 : item.dossierMaterialNumber : item === null || item === void 0 ? void 0 : item.materialNumber) === null || _ref5 === void 0 ? void 0 : _ref5.toLowerCase()) || EventValues.Empty,
              brand: (item === null || item === void 0 ? void 0 : (_item$brand = item.brand) === null || _item$brand === void 0 ? void 0 : _item$brand.toLowerCase()) || EventValues.Empty,
              quantity: group.reduce(function (acc, item) {
                return acc + item.quantity;
              }, 0) || 1,
              price: priceFormatter(avgGroupPrice),
              dimension91: marketplaceCartUrlType ? 'marketplace' : 'standard'
            };
          })
        }
      }
    };
    sendRemoveFromCartEvent(gaPayload); // GA4  remove_from_cart event

    var ecommerce = {
      currency: getCurrencyCode(((_deletedItems$2 = deletedItems[0]) === null || _deletedItems$2 === void 0 ? void 0 : _deletedItems$2.currency) || DEFAULT_CURRENCY_CODE),
      items: Object.values(itemGroups).map(function (group) {
        var _item$productId2, _item$name2, _item$name2$replace, _item$name2$replace$r, _ref6, _item$brand2;

        var item = group[0];
        var avgGroupPrice = group.reduce(function (acc, item) {
          return (item.price || 0) + acc;
        }, 0) / group.length;
        return {
          item_id: (item === null || item === void 0 ? void 0 : (_item$productId2 = item.productId) === null || _item$productId2 === void 0 ? void 0 : _item$productId2.toLowerCase()) || EventValues.Empty,
          item_name: (item === null || item === void 0 ? void 0 : (_item$name2 = item.name) === null || _item$name2 === void 0 ? void 0 : (_item$name2$replace = _item$name2.replace(regexForStripEncodingUnicode, '')) === null || _item$name2$replace === void 0 ? void 0 : (_item$name2$replace$r = _item$name2$replace.replace(regexForStripHTML, '')) === null || _item$name2$replace$r === void 0 ? void 0 : _item$name2$replace$r.toLowerCase()) || EventValues.Empty,
          item_variant: ((_ref6 = item.productType === EventValues.Emprove ? item === null || item === void 0 ? void 0 : item.dossierMaterialNumber : item === null || item === void 0 ? void 0 : item.materialNumber) === null || _ref6 === void 0 ? void 0 : _ref6.toLowerCase()) || EventValues.Empty,
          item_brand: (item === null || item === void 0 ? void 0 : (_item$brand2 = item.brand) === null || _item$brand2 === void 0 ? void 0 : _item$brand2.toLowerCase()) || EventValues.Empty,
          quantity: group.reduce(function (acc, item) {
            return acc + item.quantity;
          }, 0) || 1,
          price: priceFormatter(avgGroupPrice),
          item_type: marketplaceCartUrlType ? 'marketplace' : 'standard',
          item_list_id: RemoveFromCartEnum.ShoppingCart,
          item_list_name: RemoveFromCartEnum.ShoppingCart
        };
      })
    };
    legacySendEvent({
      payload: {
        event: 'remove_from_cart',
        addtocart_type: 'default',
        ecommerce: ecommerce
      }
    });
  }
};
export var sendRemoveBuyNow = function sendRemoveBuyNow() {
  var payload = sessionStorage.getItem(CART_DATA_STORAGE_KEY);
  var product = payload === null || payload === void 0 ? void 0 : payload.ecommerce.purchase.products[0];
  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'buy it now',
      ecommerce: {
        currency: payload === null || payload === void 0 ? void 0 : payload.ecommerce.currencyCode,
        items: [{
          item_id: product === null || product === void 0 ? void 0 : product.id,
          item_name: product === null || product === void 0 ? void 0 : product.name.replace(regexForStripEncodingUnicode, '').replace(regexForStripHTML, '').replace(regexForStripSymbols, ''),
          item_brand: product === null || product === void 0 ? void 0 : product.brand,
          item_list_id: 'slider',
          item_list_name: 'slider',
          item_variant: product === null || product === void 0 ? void 0 : product.variant,
          price: Number(product === null || product === void 0 ? void 0 : product.price),
          item_type: product === null || product === void 0 ? void 0 : product.dimension91,
          quantity: product === null || product === void 0 ? void 0 : product.quantity
        }]
      }
    }
  });
};
export var useStoreCartDataForAnalytics = function useStoreCartDataForAnalytics(queryData) {
  var _data$shippingPrefere, _data$shippingPrefere2, _data$shippingPrefere3, _data$partners, _data$partners2, _data$totals, _data$totals2, _data$totals3;

  var router = useRouter();
  if (router.asPath.includes('order_acknowledgement') || !queryData) return;
  var data = queryData === null || queryData === void 0 ? void 0 : queryData.getCart;
  var shippingMethod = data === null || data === void 0 ? void 0 : (_data$shippingPrefere = data.shippingPreference) === null || _data$shippingPrefere === void 0 ? void 0 : (_data$shippingPrefere2 = _data$shippingPrefere.participantShippingMethods) === null || _data$shippingPrefere2 === void 0 ? void 0 : (_data$shippingPrefere3 = _data$shippingPrefere2.find(function (method) {
    var _data$shippingPrefere4;

    return (method === null || method === void 0 ? void 0 : method.shippingMethodCode) === (data === null || data === void 0 ? void 0 : (_data$shippingPrefere4 = data.shippingPreference) === null || _data$shippingPrefere4 === void 0 ? void 0 : _data$shippingPrefere4.shippingMethod);
  })) === null || _data$shippingPrefere3 === void 0 ? void 0 : _data$shippingPrefere3.description;
  var itemGroups = groupBy((data === null || data === void 0 ? void 0 : data.items) || [], function (item) {
    return item.material.id;
  });
  return sessionStorage.setItem(CART_DATA_STORAGE_KEY, {
    eventCategory: GTMEventCategory.OrderConfirmation,
    eventAction: (data === null || data === void 0 ? void 0 : data.id) || EventValues.Empty,
    eventLabel: Object.values(itemGroups).map(function (group) {
      var _group$, _group$2, _group$2$material, _group$3, _group$3$material;

      return (((_group$ = group[0]) === null || _group$ === void 0 ? void 0 : _group$.type) === 'Configurator' ? ((_group$2 = group[0]) === null || _group$2 === void 0 ? void 0 : (_group$2$material = _group$2.material) === null || _group$2$material === void 0 ? void 0 : _group$2$material.number) || EventValues.Empty : ((_group$3 = group[0]) === null || _group$3 === void 0 ? void 0 : (_group$3$material = _group$3.material) === null || _group$3$material === void 0 ? void 0 : _group$3$material.product) || EventValues.Empty).toLowerCase();
    }).join('|') || EventValues.Empty,
    purchaseSoldToNumber: (data === null || data === void 0 ? void 0 : data.partners.soldTo) || EventValues.Empty,
    purchaseBillToNumber: (data === null || data === void 0 ? void 0 : (_data$partners = data.partners) === null || _data$partners === void 0 ? void 0 : _data$partners.billTo) || EventValues.Empty,
    purchaseShipToNumber: (data === null || data === void 0 ? void 0 : (_data$partners2 = data.partners) === null || _data$partners2 === void 0 ? void 0 : _data$partners2.shipTo) || EventValues.Empty,
    purchaseParticipantId: sessionStorage.getItem('PARTICIPANT_ID') || EventValues.Empty,
    eventInteractionType: 1,
    ecommerce: {
      currencyCode: getCurrencyCode((data === null || data === void 0 ? void 0 : data.currency) || DEFAULT_CURRENCY_CODE),
      purchase: {
        actionField: {
          id: (data === null || data === void 0 ? void 0 : data.id) || EventValues.Empty,
          tax: priceFormatter(data === null || data === void 0 ? void 0 : (_data$totals = data.totals) === null || _data$totals === void 0 ? void 0 : _data$totals.tax),
          shipping: priceFormatter(data === null || data === void 0 ? void 0 : (_data$totals2 = data.totals) === null || _data$totals2 === void 0 ? void 0 : _data$totals2.shipping),
          revenue: priceFormatter(data === null || data === void 0 ? void 0 : (_data$totals3 = data.totals) === null || _data$totals3 === void 0 ? void 0 : _data$totals3.total),
          coupon: EventValues.Empty,
          shippingMethod: shippingMethod
        },
        products: Object.values(itemGroups).map(function (group) {
          var _ref7, _ref8, _item$material, _item$material2, _item$material3, _item$material4, _item$material$number, _ref9, _item$material5, _item$material5$brand, _item$material6, _item$material6$brand;

          var item = group[0];
          var avgGroupPrice = group.reduce(function (acc, item) {
            var _item$pricing;

            return (((_item$pricing = item.pricing) === null || _item$pricing === void 0 ? void 0 : _item$pricing.price) || 0) + acc;
          }, 0) / group.length;
          return {
            id: ((_ref7 = item.type === 'Configurator' ? item.material.number : item.material.product) === null || _ref7 === void 0 ? void 0 : _ref7.toLowerCase()) || EventValues.Empty,
            name: ((_ref8 = item.type === 'Configurator' ? ((_item$material = item.material) === null || _item$material === void 0 ? void 0 : _item$material.description) || ((_item$material2 = item.material) === null || _item$material2 === void 0 ? void 0 : _item$material2.name) : ((_item$material3 = item.material) === null || _item$material3 === void 0 ? void 0 : _item$material3.name) || ((_item$material4 = item.material) === null || _item$material4 === void 0 ? void 0 : _item$material4.description)) === null || _ref8 === void 0 ? void 0 : _ref8.toLowerCase()) || EventValues.Empty,
            variant: ((_item$material$number = item.material.number) === null || _item$material$number === void 0 ? void 0 : _item$material$number.toLowerCase()) || EventValues.Empty,
            brand: (_ref9 = (item === null || item === void 0 ? void 0 : item.type) === 'Configurator' ? (item === null || item === void 0 ? void 0 : (_item$material5 = item.material) === null || _item$material5 === void 0 ? void 0 : (_item$material5$brand = _item$material5.brand) === null || _item$material5$brand === void 0 ? void 0 : _item$material5$brand.name) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material6 = item.material) === null || _item$material6 === void 0 ? void 0 : (_item$material6$brand = _item$material6.brand) === null || _item$material6$brand === void 0 ? void 0 : _item$material6$brand.key) || EventValues.Empty) === null || _ref9 === void 0 ? void 0 : _ref9.toLowerCase(),
            quantity: group.reduce(function (acc, item) {
              return acc + item.quantity;
            }, 0) || 1,
            price: priceFormatter(avgGroupPrice),
            dimension91: 'standard',
            coupon: item.promoCode || EventValues.Empty
          };
        })
      }
    }
  });
};
export var sendOrderConfirmationEvent = function sendOrderConfirmationEvent(payload) {
  try {
    var _payload$items, _payload$items2, _promoProducts, _payload$total, _payload$items3, _payload$shipTo, _payload$shipTo2, _payload$billTo, _payload$billTo2, _payload$billTo3, _payload$billTo4, _payload$shipTo3, _payload$shipTo4, _ecommerce$purchase, _ecommerce$purchase$p;

    if (!payload || !showGTMTag) return;
    var nonBundleItems = payload === null || payload === void 0 ? void 0 : (_payload$items = payload.items) === null || _payload$items === void 0 ? void 0 : _payload$items.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.type) !== 'PromotionalBundle';
    });
    var itemGroups = groupBy(nonBundleItems || [], function (item) {
      var _item$emproveDossierI, _item$material7;

      return (item === null || item === void 0 ? void 0 : item.type) === EventValues.Emprove ? item === null || item === void 0 ? void 0 : (_item$emproveDossierI = item.emproveDossierInfo) === null || _item$emproveDossierI === void 0 ? void 0 : _item$emproveDossierI.dossierMaterialNumber : item === null || item === void 0 ? void 0 : (_item$material7 = item.material) === null || _item$material7 === void 0 ? void 0 : _item$material7.id;
    });
    var nonBundleproducts = Object.values(itemGroups).map(function (group) {
      var item = group[0];
      var avgGroupPrice = group.reduce(function (acc, item) {
        return ((item === null || item === void 0 ? void 0 : item.pricePerUnit) || (item === null || item === void 0 ? void 0 : item.listPrice) || 0) + acc;
      }, 0) / group.length;
      var discount = calculateDiscountPercentage(item === null || item === void 0 ? void 0 : item.listPrice, item === null || item === void 0 ? void 0 : item.pricePerUnit);

      if (item.type === EventValues.Emprove) {
        var _item$material8, _item$material8$id, _item$material9, _item$material9$descr, _item$material9$descr2, _item$material9$descr3, _item$material10, _item$material10$name, _item$material10$name2, _item$material10$name3, _item$emproveDossierI2, _item$emproveDossierI3, _item$material11, _item$material11$bran, _item$material11$bran2, _item$promoCode;

        return {
          id: (item === null || item === void 0 ? void 0 : (_item$material8 = item.material) === null || _item$material8 === void 0 ? void 0 : (_item$material8$id = _item$material8.id) === null || _item$material8$id === void 0 ? void 0 : _item$material8$id.toLowerCase()) || EventValues.Empty,
          name: (item === null || item === void 0 ? void 0 : (_item$material9 = item.material) === null || _item$material9 === void 0 ? void 0 : (_item$material9$descr = _item$material9.description) === null || _item$material9$descr === void 0 ? void 0 : (_item$material9$descr2 = _item$material9$descr.replace(regexForStripEncodingUnicode, '')) === null || _item$material9$descr2 === void 0 ? void 0 : (_item$material9$descr3 = _item$material9$descr2.replace(regexForStripHTML, '')) === null || _item$material9$descr3 === void 0 ? void 0 : _item$material9$descr3.toLowerCase()) || (item === null || item === void 0 ? void 0 : (_item$material10 = item.material) === null || _item$material10 === void 0 ? void 0 : (_item$material10$name = _item$material10.name) === null || _item$material10$name === void 0 ? void 0 : (_item$material10$name2 = _item$material10$name.replace(regexForStripEncodingUnicode, '')) === null || _item$material10$name2 === void 0 ? void 0 : (_item$material10$name3 = _item$material10$name2.replace(regexForStripHTML, '')) === null || _item$material10$name3 === void 0 ? void 0 : _item$material10$name3.toLowerCase()) || EventValues.Empty,
          variant: (item === null || item === void 0 ? void 0 : (_item$emproveDossierI2 = item.emproveDossierInfo) === null || _item$emproveDossierI2 === void 0 ? void 0 : (_item$emproveDossierI3 = _item$emproveDossierI2.dossierMaterialNumber) === null || _item$emproveDossierI3 === void 0 ? void 0 : _item$emproveDossierI3.toLowerCase()) || EventValues.Empty,
          brand: (item === null || item === void 0 ? void 0 : (_item$material11 = item.material) === null || _item$material11 === void 0 ? void 0 : (_item$material11$bran = _item$material11.brand) === null || _item$material11$bran === void 0 ? void 0 : (_item$material11$bran2 = _item$material11$bran.name) === null || _item$material11$bran2 === void 0 ? void 0 : _item$material11$bran2.toLowerCase()) || EventValues.SialBrand,
          quantity: 1,
          price: priceFormatter(avgGroupPrice),
          coupon: (item === null || item === void 0 ? void 0 : (_item$promoCode = item.promoCode) === null || _item$promoCode === void 0 ? void 0 : _item$promoCode.toLowerCase()) || undefined,
          discount: discount,
          dimension91: payload.orderType === 'marketplace' ? 'marketplace' : 'standard'
        };
      } else {
        var _ref10, _item$material12, _item$material13, _ref11, _ref11$replace, _ref11$replace$replac, _item$material14, _item$material15, _item$material16, _item$material17, _item$material18, _item$material18$numb, _ref12, _item$material19, _item$material19$bran, _item$material20, _item$material20$bran, _item$promoCode2;

        return {
          id: (_ref10 = (item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material12 = item.material) === null || _item$material12 === void 0 ? void 0 : _item$material12.number) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material13 = item.material) === null || _item$material13 === void 0 ? void 0 : _item$material13.product) || EventValues.Empty) === null || _ref10 === void 0 ? void 0 : _ref10.toLowerCase(),
          name: ((_ref11 = (item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? ((_item$material14 = item.material) === null || _item$material14 === void 0 ? void 0 : _item$material14.description) || ((_item$material15 = item.material) === null || _item$material15 === void 0 ? void 0 : _item$material15.name) : ((_item$material16 = item.material) === null || _item$material16 === void 0 ? void 0 : _item$material16.name) || ((_item$material17 = item.material) === null || _item$material17 === void 0 ? void 0 : _item$material17.description)) === null || _ref11 === void 0 ? void 0 : (_ref11$replace = _ref11.replace(regexForStripHTML, '')) === null || _ref11$replace === void 0 ? void 0 : (_ref11$replace$replac = _ref11$replace.replace(regexForStripEncodingUnicode, '')) === null || _ref11$replace$replac === void 0 ? void 0 : _ref11$replace$replac.toLowerCase()) || EventValues.Empty,
          variant: (item === null || item === void 0 ? void 0 : (_item$material18 = item.material) === null || _item$material18 === void 0 ? void 0 : (_item$material18$numb = _item$material18.number) === null || _item$material18$numb === void 0 ? void 0 : _item$material18$numb.toLowerCase()) || EventValues.Empty,
          brand: (_ref12 = (item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material19 = item.material) === null || _item$material19 === void 0 ? void 0 : (_item$material19$bran = _item$material19.brand) === null || _item$material19$bran === void 0 ? void 0 : _item$material19$bran.name) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material20 = item.material) === null || _item$material20 === void 0 ? void 0 : (_item$material20$bran = _item$material20.brand) === null || _item$material20$bran === void 0 ? void 0 : _item$material20$bran.key) || EventValues.Empty) === null || _ref12 === void 0 ? void 0 : _ref12.toLowerCase(),
          quantity: group.reduce(function (acc, item) {
            return acc + item.quantity;
          }, 0) || 1,
          price: priceFormatter(avgGroupPrice),
          coupon: (item === null || item === void 0 ? void 0 : (_item$promoCode2 = item.promoCode) === null || _item$promoCode2 === void 0 ? void 0 : _item$promoCode2.toLowerCase()) || undefined,
          discount: discount,
          dimension91: payload.orderType === 'marketplace' ? 'marketplace' : 'standard'
        };
      }
    });
    var bundleItems = payload === null || payload === void 0 ? void 0 : (_payload$items2 = payload.items) === null || _payload$items2 === void 0 ? void 0 : _payload$items2.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.type) === 'PromotionalBundle';
    });
    var promoProducts = [];

    if (bundleItems.length) {
      var promoItems = groupBy(bundleItems || [], function (item) {
        var _item$material21;

        return item === null || item === void 0 ? void 0 : (_item$material21 = item.material) === null || _item$material21 === void 0 ? void 0 : _item$material21.id;
      });
      forEach(promoItems, function (_value, key) {
        promoItems[key] = groupBy(promoItems[key] || [], function (item) {
          return item === null || item === void 0 ? void 0 : item.promoCode;
        });
      });
      promoProducts = Object.values(promoItems).map(function (group) {
        return Object.values(group).map(function (group1) {
          var _ref13, _item$material22, _ref14, _ref14$replace, _ref14$replace$replac, _item$material23, _item$material24, _item$material25, _item$material25$numb, _ref15, _item$material26, _item$material26$bran, _item$promoCode3;

          var item = group1[0];
          var avgGroupPrice = group1.reduce(function (acc, item) {
            return ((item === null || item === void 0 ? void 0 : item.pricePerUnit) || 0) + acc;
          }, 0) / group1.length;
          return {
            id: (_ref13 = (item === null || item === void 0 ? void 0 : (_item$material22 = item.material) === null || _item$material22 === void 0 ? void 0 : _item$material22.product) || EventValues.Empty) === null || _ref13 === void 0 ? void 0 : _ref13.toLowerCase(),
            name: ((_ref14 = ((_item$material23 = item.material) === null || _item$material23 === void 0 ? void 0 : _item$material23.name) || ((_item$material24 = item.material) === null || _item$material24 === void 0 ? void 0 : _item$material24.description)) === null || _ref14 === void 0 ? void 0 : (_ref14$replace = _ref14.replace(regexForStripHTML, '')) === null || _ref14$replace === void 0 ? void 0 : (_ref14$replace$replac = _ref14$replace.replace(regexForStripEncodingUnicode, '')) === null || _ref14$replace$replac === void 0 ? void 0 : _ref14$replace$replac.toLowerCase()) || EventValues.Empty,
            variant: (item === null || item === void 0 ? void 0 : (_item$material25 = item.material) === null || _item$material25 === void 0 ? void 0 : (_item$material25$numb = _item$material25.number) === null || _item$material25$numb === void 0 ? void 0 : _item$material25$numb.toLowerCase()) || EventValues.Empty,
            brand: (_ref15 = (item === null || item === void 0 ? void 0 : (_item$material26 = item.material) === null || _item$material26 === void 0 ? void 0 : (_item$material26$bran = _item$material26.brand) === null || _item$material26$bran === void 0 ? void 0 : _item$material26$bran.key) || EventValues.Empty) === null || _ref15 === void 0 ? void 0 : _ref15.toLowerCase(),
            quantity: group1.reduce(function (acc, item) {
              return acc + (item === null || item === void 0 ? void 0 : item.quantity);
            }, 0) || 1,
            price: priceFormatter(avgGroupPrice),
            coupon: (item === null || item === void 0 ? void 0 : (_item$promoCode3 = item.promoCode) === null || _item$promoCode3 === void 0 ? void 0 : _item$promoCode3.toLowerCase()) || undefined,
            discount: calculateDiscountPercentage(item === null || item === void 0 ? void 0 : item.listPrice, item === null || item === void 0 ? void 0 : item.pricePerUnit),
            dimension91: payload.orderType === 'marketplace' ? 'marketplace' : 'standard'
          };
        });
      });
    }

    var flattenedProd = promoProducts.length && ((_promoProducts = promoProducts) === null || _promoProducts === void 0 ? void 0 : _promoProducts.flat(1)) || []; // shipping includes all charges except subtotal and tax

    var shippingCharges = (payload === null || payload === void 0 ? void 0 : payload.transHandlingCharge) + (payload === null || payload === void 0 ? void 0 : payload.ice) + (payload === null || payload === void 0 ? void 0 : payload.electricalFee) + (payload === null || payload === void 0 ? void 0 : payload.eurVolatileMassCharge) + (payload === null || payload === void 0 ? void 0 : payload.dutyTotal) + (payload === null || payload === void 0 ? void 0 : payload.pkg);
    var ecommerce = {
      currencyCode: getCurrencyCode((payload === null || payload === void 0 ? void 0 : payload.currency) || DEFAULT_CURRENCY_CODE),
      purchase: {
        actionField: {
          id: (payload === null || payload === void 0 ? void 0 : payload.orderNumber) || (payload === null || payload === void 0 ? void 0 : payload.orderId) || EventValues.Empty,
          tax: priceFormatter(payload === null || payload === void 0 ? void 0 : payload.salesTax),
          shipping: priceFormatter(payload === null || payload === void 0 ? void 0 : payload.transHandlingCharge),
          revenue: (payload === null || payload === void 0 ? void 0 : (_payload$total = payload.total) === null || _payload$total === void 0 ? void 0 : _payload$total.toString()) || '0.00',
          coupon: undefined
        },
        products: [].concat(_toConsumableArray(nonBundleproducts), _toConsumableArray(flattenedProd))
      }
    };
    var paymentMethod = mapPaymentMethod(payload.paymentInfo.paymentMethod, payload.chargebackCodes.length > 0, payload.contractNumber);
    var subTotal = payload === null || payload === void 0 ? void 0 : (_payload$items3 = payload.items) === null || _payload$items3 === void 0 ? void 0 : _payload$items3.reduce(function (acc, item) {
      return (item.pricePerUnit * item.quantity || 0) + acc;
    }, 0); // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
    //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024

    legacySendEvent({
      eventType: GTMEventTypes.PurchaseConfirmation,
      payload: {
        eventCategory: GTMEventCategory.OrderConfirmation.toLowerCase(),
        deepOrderID: (payload === null || payload === void 0 ? void 0 : payload.readableOrderId) || EventValues.Empty,
        eventAction: (payload === null || payload === void 0 ? void 0 : payload.orderNumber) || (payload === null || payload === void 0 ? void 0 : payload.orderId) || EventValues.Empty,
        eventLabel: [].concat(_toConsumableArray(Object.values(itemGroups).map(function (group) {
          var _group$4, _group$4$material, _group$4$material$id, _group$5, _group$5$material, _group$5$material$num, _group$6, _group$6$material, _group$6$material$pro;

          return group[0].type === EventValues.Emprove ? (_group$4 = group[0]) === null || _group$4 === void 0 ? void 0 : (_group$4$material = _group$4.material) === null || _group$4$material === void 0 ? void 0 : (_group$4$material$id = _group$4$material.id) === null || _group$4$material$id === void 0 ? void 0 : _group$4$material$id.toLowerCase() : group[0].type === ConfiguratorProductType.Configurator ? (_group$5 = group[0]) === null || _group$5 === void 0 ? void 0 : (_group$5$material = _group$5.material) === null || _group$5$material === void 0 ? void 0 : (_group$5$material$num = _group$5$material.number) === null || _group$5$material$num === void 0 ? void 0 : _group$5$material$num.toLowerCase() : (_group$6 = group[0]) === null || _group$6 === void 0 ? void 0 : (_group$6$material = _group$6.material) === null || _group$6$material === void 0 ? void 0 : (_group$6$material$pro = _group$6$material.product) === null || _group$6$material$pro === void 0 ? void 0 : _group$6$material$pro.toLowerCase();
        })), _toConsumableArray(flattenedProd.map(function (item) {
          return item === null || item === void 0 ? void 0 : item.id;
        }))).join('|') || EventValues.Empty,
        purchaseSoldToNumber: (payload === null || payload === void 0 ? void 0 : payload.soldTo) || EventValues.Empty,
        purchaseShipToNumber: (payload === null || payload === void 0 ? void 0 : (_payload$shipTo = payload.shipTo) === null || _payload$shipTo === void 0 ? void 0 : _payload$shipTo.partnerNumber) || (payload === null || payload === void 0 ? void 0 : (_payload$shipTo2 = payload.shipTo) === null || _payload$shipTo2 === void 0 ? void 0 : _payload$shipTo2.customerNumber) || EventValues.NotAvailable,
        purchaseBillToNumber: (payload === null || payload === void 0 ? void 0 : (_payload$billTo = payload.billTo) === null || _payload$billTo === void 0 ? void 0 : _payload$billTo.partnerNumber) || (payload === null || payload === void 0 ? void 0 : (_payload$billTo2 = payload.billTo) === null || _payload$billTo2 === void 0 ? void 0 : _payload$billTo2.customerNumber) || EventValues.NotAvailable,
        purchaseParticipantId: sessionStorage.getItem('PARTICIPANT_ID') || EventValues.NotAvailable,
        paymentMethod: paymentMethod,
        eventInteractionType: 1,
        ecommerce: ecommerce,
        orderType: payload.orderType === 'marketplace' ? 'marketplace' : 'standard'
      }
    }); // GA4 - Order Confirmation

    legacySendEvent({
      payload: {
        event: 'purchase',
        addtocart_type: payload.cartType === 'buyitnowcart' ? 'buy it now' : 'default',
        order_type: payload.orderType === 'marketplace' ? 'marketplace' : 'standard',
        deep_order_id: (payload === null || payload === void 0 ? void 0 : payload.readableOrderId) || EventValues.Empty,
        order_participant_id: sessionStorage.getItem('PARTICIPANT_ID') || EventValues.NotAvailable,
        order_sold_to: (payload === null || payload === void 0 ? void 0 : payload.soldTo) || EventValues.Empty,
        order_bill_to: (payload === null || payload === void 0 ? void 0 : (_payload$billTo3 = payload.billTo) === null || _payload$billTo3 === void 0 ? void 0 : _payload$billTo3.partnerNumber) || (payload === null || payload === void 0 ? void 0 : (_payload$billTo4 = payload.billTo) === null || _payload$billTo4 === void 0 ? void 0 : _payload$billTo4.customerNumber) || EventValues.NotAvailable,
        order_ship_to: (payload === null || payload === void 0 ? void 0 : (_payload$shipTo3 = payload.shipTo) === null || _payload$shipTo3 === void 0 ? void 0 : _payload$shipTo3.partnerNumber) || (payload === null || payload === void 0 ? void 0 : (_payload$shipTo4 = payload.shipTo) === null || _payload$shipTo4 === void 0 ? void 0 : _payload$shipTo4.customerNumber) || EventValues.NotAvailable,
        payment_type: paymentMethod,
        ecommerce: {
          transaction_id: (payload === null || payload === void 0 ? void 0 : payload.orderNumber) || (payload === null || payload === void 0 ? void 0 : payload.orderId) || EventValues.Empty,
          value: subTotal || 1,
          tax: priceFormatter(payload === null || payload === void 0 ? void 0 : payload.salesTax),
          shipping: priceFormatter(shippingCharges),
          coupon: undefined,
          currency: getCurrencyCode((payload === null || payload === void 0 ? void 0 : payload.currency) || DEFAULT_CURRENCY_CODE),
          items: ecommerce === null || ecommerce === void 0 ? void 0 : (_ecommerce$purchase = ecommerce.purchase) === null || _ecommerce$purchase === void 0 ? void 0 : (_ecommerce$purchase$p = _ecommerce$purchase.products) === null || _ecommerce$purchase$p === void 0 ? void 0 : _ecommerce$purchase$p.map(function (product) {
            var _product$quantity3;

            return {
              item_id: product.id,
              item_name: product.name,
              coupon: product.coupon,
              discount: product.discount,
              item_brand: product.brand,
              item_variant: product.variant,
              price: priceFormatter(product === null || product === void 0 ? void 0 : product.price),
              item_type: product.dimension91,
              quantity: (_product$quantity3 = product.quantity) !== null && _product$quantity3 !== void 0 ? _product$quantity3 : 1
            };
          })
        }
      }
    });
  } catch (error) {
    console.error('ERROR - Sending Order Confirmation Event: ', error);
  }
};
export var sendSaveForLaterEvent = function sendSaveForLaterEvent(_ref16) {
  var action = _ref16.action,
      label = _ref16.label;

  var _ref17 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref17$clickType = _ref17.clickType,
      clickType = _ref17$clickType === void 0 ? 'page' : _ref17$clickType,
      clickAction = _ref17.clickAction,
      clickDetails = _ref17.clickDetails,
      isMarketplaceCart = _ref17.isMarketplaceCart,
      _ref17$component = _ref17.component,
      component = _ref17$component === void 0 ? 'grid' : _ref17$component,
      elementType = _ref17.elementType,
      linkText = _ref17.linkText,
      linkUrl = _ref17.linkUrl;

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'saved_for_later_clicks',
      click_type: "saved for later : ".concat(component === 'grid' ? component : clickType),
      click_action: clickAction || action,
      click_details: clickDetails,
      cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      component: component || undefined,
      element_type: elementType || undefined,
      link_text: linkText || action || undefined,
      link_url: linkUrl,
      //Legacy UA event data
      eventCategory: 'save for later',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0
    }
  });
};
export var sendAddToSaveForLaterEcommerceEvent = function sendAddToSaveForLaterEcommerceEvent(item, isMarketplaceCart) {
  var _item$material27, _item$material27$prod, _item$material28, _item$material28$numb, _item$pricing2;

  var ecommerce = {
    currency: item.currency || DEFAULT_CURRENCY_CODE,
    items: [{
      item_id: item === null || item === void 0 ? void 0 : (_item$material27 = item.material) === null || _item$material27 === void 0 ? void 0 : (_item$material27$prod = _item$material27.product) === null || _item$material27$prod === void 0 ? void 0 : _item$material27$prod.toLowerCase(),
      item_name: item.material.name.replace(regexForStripEncodingUnicode, '').replace(regexForStripHTML, '').toLowerCase() || EventValues.Empty,
      item_variant: item === null || item === void 0 ? void 0 : (_item$material28 = item.material) === null || _item$material28 === void 0 ? void 0 : (_item$material28$numb = _item$material28.number) === null || _item$material28$numb === void 0 ? void 0 : _item$material28$numb.toLowerCase(),
      item_brand: item.material.brand.key.toLowerCase() || EventValues.NotAvailable,
      quantity: item.quantity || 1,
      price: priceFormatter(item === null || item === void 0 ? void 0 : (_item$pricing2 = item.pricing) === null || _item$pricing2 === void 0 ? void 0 : _item$pricing2.price),
      item_type: isMarketplaceCart ? 'marketplace' : 'standard',
      item_list_id: 'saved_for_later',
      item_list_name: 'saved_for_later'
    }]
  }; // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024

  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'default',
      ecommerce: ecommerce
    }
  });
};
export var sendAddToCartFromSaveForLaterEcommerceEvent = function sendAddToCartFromSaveForLaterEcommerceEvent(item, isMarketplaceCart) {
  var _item$material29, _item$material29$prod, _item$material30, _item$material30$numb;

  var ecommerce = {
    currency: item.currency || DEFAULT_CURRENCY_CODE,
    value: 1,
    items: [{
      item_id: item === null || item === void 0 ? void 0 : (_item$material29 = item.material) === null || _item$material29 === void 0 ? void 0 : (_item$material29$prod = _item$material29.product) === null || _item$material29$prod === void 0 ? void 0 : _item$material29$prod.toLowerCase(),
      item_name: item.material.description.replace(regexForStripEncodingUnicode, '').replace(regexForStripHTML, '').toLowerCase() || EventValues.Empty,
      discount: 0,
      item_variant: item === null || item === void 0 ? void 0 : (_item$material30 = item.material) === null || _item$material30 === void 0 ? void 0 : (_item$material30$numb = _item$material30.number) === null || _item$material30$numb === void 0 ? void 0 : _item$material30$numb.toLowerCase(),
      item_brand: item.material.brand.key.toLowerCase() || EventValues.NotAvailable,
      quantity: 1,
      price: 1,
      item_type: isMarketplaceCart ? 'marketplace' : 'standard',
      item_list_id: 'saved_for_later',
      item_list_name: 'saved_for_later'
    }]
  }; // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024

  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: ecommerce
    }
  });
};
export var handleGARemoveFromCart = function handleGARemoveFromCart(items, cartPage, marketplacecart, currency) {
  var _items$3, _items$4;

  var itemGroups = groupBy(items || [], function (item) {
    var _item$emproveDossierI4;

    return (item === null || item === void 0 ? void 0 : item.type) === CartItemType.Emprove ? item === null || item === void 0 ? void 0 : (_item$emproveDossierI4 = item.emproveDossierInfo) === null || _item$emproveDossierI4 === void 0 ? void 0 : _item$emproveDossierI4.dossierMaterialNumber : item.material.id;
  });
  var cartPayload = {
    productIds: Object.values(itemGroups).map(function (group) {
      var _group$7, _group$7$material, _group$7$material$id, _group$8, _group$8$material, _group$8$material$num, _group$9, _group$9$material, _group$9$material$pro;

      return group[0].type === CartItemType.Emprove ? (_group$7 = group[0]) === null || _group$7 === void 0 ? void 0 : (_group$7$material = _group$7.material) === null || _group$7$material === void 0 ? void 0 : (_group$7$material$id = _group$7$material.id) === null || _group$7$material$id === void 0 ? void 0 : _group$7$material$id.toLowerCase() : group[0].type === CartItemType.Configurator ? (_group$8 = group[0]) === null || _group$8 === void 0 ? void 0 : (_group$8$material = _group$8.material) === null || _group$8$material === void 0 ? void 0 : (_group$8$material$num = _group$8$material.number) === null || _group$8$material$num === void 0 ? void 0 : _group$8$material$num.toLowerCase() : (_group$9 = group[0]) === null || _group$9 === void 0 ? void 0 : (_group$9$material = _group$9.material) === null || _group$9$material === void 0 ? void 0 : (_group$9$material$pro = _group$9$material.product) === null || _group$9$material$pro === void 0 ? void 0 : _group$9$material$pro.toLowerCase();
    }).join('|') || EventValues.Empty,
    actionTitle: cartPage === null || cartPage === void 0 ? void 0 : cartPage.toLowerCase(),
    ecommerce: {
      currencyCode: items && getCurrencyCode(((_items$3 = items[0]) === null || _items$3 === void 0 ? void 0 : _items$3.currency) || DEFAULT_CURRENCY_CODE),
      remove: {
        actionField: {},
        products: Object.values(itemGroups).map(function (group) {
          var item = group[0];
          var avgGroupPrice = group.reduce(function (acc, item) {
            var _item$pricing3;

            return (((_item$pricing3 = item.pricing) === null || _item$pricing3 === void 0 ? void 0 : _item$pricing3.price) || 0) + acc;
          }, 0) / group.length;

          if (item.type === CartItemType.Emprove) {
            var _item$material31, _item$material31$id, _item$material32, _item$material32$desc, _item$material32$desc2, _item$material32$desc3, _item$material33, _item$material33$name, _item$material33$name2, _item$material33$name3, _item$emproveDossierI5, _item$emproveDossierI6, _item$material34, _item$material34$bran, _item$material34$bran2;

            return {
              id: (item === null || item === void 0 ? void 0 : (_item$material31 = item.material) === null || _item$material31 === void 0 ? void 0 : (_item$material31$id = _item$material31.id) === null || _item$material31$id === void 0 ? void 0 : _item$material31$id.toLowerCase()) || EventValues.Empty,
              name: (item === null || item === void 0 ? void 0 : (_item$material32 = item.material) === null || _item$material32 === void 0 ? void 0 : (_item$material32$desc = _item$material32.description) === null || _item$material32$desc === void 0 ? void 0 : (_item$material32$desc2 = _item$material32$desc.replace(regexForStripEncodingUnicode, '')) === null || _item$material32$desc2 === void 0 ? void 0 : (_item$material32$desc3 = _item$material32$desc2.replace(regexForStripHTML, '')) === null || _item$material32$desc3 === void 0 ? void 0 : _item$material32$desc3.toLowerCase()) || (item === null || item === void 0 ? void 0 : (_item$material33 = item.material) === null || _item$material33 === void 0 ? void 0 : (_item$material33$name = _item$material33.name) === null || _item$material33$name === void 0 ? void 0 : (_item$material33$name2 = _item$material33$name.replace(regexForStripEncodingUnicode, '')) === null || _item$material33$name2 === void 0 ? void 0 : (_item$material33$name3 = _item$material33$name2.replace(regexForStripHTML, '')) === null || _item$material33$name3 === void 0 ? void 0 : _item$material33$name3.toLowerCase()) || EventValues.Empty,
              variant: (item === null || item === void 0 ? void 0 : (_item$emproveDossierI5 = item.emproveDossierInfo) === null || _item$emproveDossierI5 === void 0 ? void 0 : (_item$emproveDossierI6 = _item$emproveDossierI5.dossierMaterialNumber) === null || _item$emproveDossierI6 === void 0 ? void 0 : _item$emproveDossierI6.toLowerCase()) || EventValues.Empty,
              brand: (item === null || item === void 0 ? void 0 : (_item$material34 = item.material) === null || _item$material34 === void 0 ? void 0 : (_item$material34$bran = _item$material34.brand) === null || _item$material34$bran === void 0 ? void 0 : (_item$material34$bran2 = _item$material34$bran.name) === null || _item$material34$bran2 === void 0 ? void 0 : _item$material34$bran2.toLowerCase()) || EventValues.SialBrand || EventValues.Empty,
              quantity: 1,
              price: cartPage !== EventValues.Slider ? priceFormatter(avgGroupPrice) : null,
              dimension91: marketplacecart ? Dimension91.Marketplace : Dimension91.Standard
            };
          } else {
            var _item$material35, _item$material36, _ref18, _ref18$replace, _ref18$replace$replac, _item$material37, _item$material38, _item$material39, _item$material40, _item$material41, _item$material41$numb, _ref19, _item$material42, _item$material42$bran, _item$material43, _item$material43$bran;

            return {
              id: ((item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? ((_item$material35 = item.material) === null || _item$material35 === void 0 ? void 0 : _item$material35.number) || EventValues.Empty : ((_item$material36 = item.material) === null || _item$material36 === void 0 ? void 0 : _item$material36.product) || EventValues.Empty).toLowerCase(),
              name: (_ref18 = ((item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? ((_item$material37 = item.material) === null || _item$material37 === void 0 ? void 0 : _item$material37.description) || ((_item$material38 = item.material) === null || _item$material38 === void 0 ? void 0 : _item$material38.name) : ((_item$material39 = item.material) === null || _item$material39 === void 0 ? void 0 : _item$material39.name) || ((_item$material40 = item.material) === null || _item$material40 === void 0 ? void 0 : _item$material40.description)) || EventValues.Empty) === null || _ref18 === void 0 ? void 0 : (_ref18$replace = _ref18.replace(regexForStripHTML, '')) === null || _ref18$replace === void 0 ? void 0 : (_ref18$replace$replac = _ref18$replace.replace(regexForStripEncodingUnicode, '')) === null || _ref18$replace$replac === void 0 ? void 0 : _ref18$replace$replac.toLowerCase(),
              variant: (item === null || item === void 0 ? void 0 : (_item$material41 = item.material) === null || _item$material41 === void 0 ? void 0 : (_item$material41$numb = _item$material41.number) === null || _item$material41$numb === void 0 ? void 0 : _item$material41$numb.toLowerCase()) || EventValues.Empty,
              brand: (_ref19 = (item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material42 = item.material) === null || _item$material42 === void 0 ? void 0 : (_item$material42$bran = _item$material42.brand) === null || _item$material42$bran === void 0 ? void 0 : _item$material42$bran.name) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material43 = item.material) === null || _item$material43 === void 0 ? void 0 : (_item$material43$bran = _item$material43.brand) === null || _item$material43$bran === void 0 ? void 0 : _item$material43$bran.key) || EventValues.Empty) === null || _ref19 === void 0 ? void 0 : _ref19.toLowerCase(),
              quantity: group.reduce(function (acc, item) {
                return acc + item.quantity;
              }, 0) || 1,
              price: cartPage !== EventValues.Slider ? priceFormatter(avgGroupPrice) : null,
              dimension91: marketplacecart ? Dimension91.Marketplace : Dimension91.Standard
            };
          }
        }) || []
      }
    }
  };
  sendRemoveFromCartEvent(cartPayload); // GA4  remove_from_cart event

  var currencyValue = currency !== null && currency !== void 0 ? currency : (_items$4 = items[0]) === null || _items$4 === void 0 ? void 0 : _items$4.currency;
  var ecommerce = {
    currency: getCurrencyCode(currencyValue || DEFAULT_CURRENCY_CODE),
    items: Object.values(itemGroups).map(function (group) {
      var item = group[0];
      var avgGroupPrice = group.reduce(function (acc, item) {
        var _item$pricing4;

        return (((_item$pricing4 = item.pricing) === null || _item$pricing4 === void 0 ? void 0 : _item$pricing4.price) || 0) + acc;
      }, 0) / group.length;

      if (item.type === CartItemType.Emprove) {
        var _item$material44, _item$material44$id, _item$material45, _item$material45$desc, _item$material45$desc2, _item$material45$desc3, _item$material46, _item$material46$name, _item$material46$name2, _item$material46$name3, _item$emproveDossierI7, _item$emproveDossierI8, _item$material47, _item$material47$bran, _item$material47$bran2;

        return {
          item_id: (item === null || item === void 0 ? void 0 : (_item$material44 = item.material) === null || _item$material44 === void 0 ? void 0 : (_item$material44$id = _item$material44.id) === null || _item$material44$id === void 0 ? void 0 : _item$material44$id.toLowerCase()) || EventValues.Empty,
          item_name: (item === null || item === void 0 ? void 0 : (_item$material45 = item.material) === null || _item$material45 === void 0 ? void 0 : (_item$material45$desc = _item$material45.description) === null || _item$material45$desc === void 0 ? void 0 : (_item$material45$desc2 = _item$material45$desc.replace(regexForStripEncodingUnicode, '')) === null || _item$material45$desc2 === void 0 ? void 0 : (_item$material45$desc3 = _item$material45$desc2.replace(regexForStripHTML, '')) === null || _item$material45$desc3 === void 0 ? void 0 : _item$material45$desc3.toLowerCase()) || (item === null || item === void 0 ? void 0 : (_item$material46 = item.material) === null || _item$material46 === void 0 ? void 0 : (_item$material46$name = _item$material46.name) === null || _item$material46$name === void 0 ? void 0 : (_item$material46$name2 = _item$material46$name.replace(regexForStripEncodingUnicode, '')) === null || _item$material46$name2 === void 0 ? void 0 : (_item$material46$name3 = _item$material46$name2.replace(regexForStripHTML, '')) === null || _item$material46$name3 === void 0 ? void 0 : _item$material46$name3.toLowerCase()) || EventValues.Empty,
          item_variant: (item === null || item === void 0 ? void 0 : (_item$emproveDossierI7 = item.emproveDossierInfo) === null || _item$emproveDossierI7 === void 0 ? void 0 : (_item$emproveDossierI8 = _item$emproveDossierI7.dossierMaterialNumber) === null || _item$emproveDossierI8 === void 0 ? void 0 : _item$emproveDossierI8.toLowerCase()) || EventValues.Empty,
          item_brand: (item === null || item === void 0 ? void 0 : (_item$material47 = item.material) === null || _item$material47 === void 0 ? void 0 : (_item$material47$bran = _item$material47.brand) === null || _item$material47$bran === void 0 ? void 0 : (_item$material47$bran2 = _item$material47$bran.name) === null || _item$material47$bran2 === void 0 ? void 0 : _item$material47$bran2.toLowerCase()) || EventValues.SialBrand || EventValues.Empty,
          item_list_id: cartPage === null || cartPage === void 0 ? void 0 : cartPage.toLowerCase(),
          item_list_name: cartPage === null || cartPage === void 0 ? void 0 : cartPage.toLowerCase(),
          quantity: 1,
          price: cartPage !== EventValues.Slider ? priceFormatter(avgGroupPrice) : null,
          item_type: marketplacecart ? 'marketplace' : 'standard'
        };
      } else {
        var _item$material48, _item$material49, _ref20, _ref20$replace, _ref20$replace$replac, _item$material50, _item$material51, _item$material52, _item$material53, _item$material54, _item$material54$numb, _ref21, _item$material55, _item$material55$bran, _item$material56, _item$material56$bran;

        return {
          item_id: ((item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? ((_item$material48 = item.material) === null || _item$material48 === void 0 ? void 0 : _item$material48.number) || EventValues.Empty : ((_item$material49 = item.material) === null || _item$material49 === void 0 ? void 0 : _item$material49.product) || EventValues.Empty).toLowerCase(),
          item_name: (_ref20 = ((item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? ((_item$material50 = item.material) === null || _item$material50 === void 0 ? void 0 : _item$material50.description) || ((_item$material51 = item.material) === null || _item$material51 === void 0 ? void 0 : _item$material51.name) : ((_item$material52 = item.material) === null || _item$material52 === void 0 ? void 0 : _item$material52.name) || ((_item$material53 = item.material) === null || _item$material53 === void 0 ? void 0 : _item$material53.description)) || EventValues.Empty) === null || _ref20 === void 0 ? void 0 : (_ref20$replace = _ref20.replace(regexForStripHTML, '')) === null || _ref20$replace === void 0 ? void 0 : (_ref20$replace$replac = _ref20$replace.replace(regexForStripEncodingUnicode, '')) === null || _ref20$replace$replac === void 0 ? void 0 : _ref20$replace$replac.toLowerCase(),
          item_variant: (item === null || item === void 0 ? void 0 : (_item$material54 = item.material) === null || _item$material54 === void 0 ? void 0 : (_item$material54$numb = _item$material54.number) === null || _item$material54$numb === void 0 ? void 0 : _item$material54$numb.toLowerCase()) || EventValues.Empty,
          item_brand: (_ref21 = (item === null || item === void 0 ? void 0 : item.type) === CartItemType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material55 = item.material) === null || _item$material55 === void 0 ? void 0 : (_item$material55$bran = _item$material55.brand) === null || _item$material55$bran === void 0 ? void 0 : _item$material55$bran.name) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material56 = item.material) === null || _item$material56 === void 0 ? void 0 : (_item$material56$bran = _item$material56.brand) === null || _item$material56$bran === void 0 ? void 0 : _item$material56$bran.key) || EventValues.Empty) === null || _ref21 === void 0 ? void 0 : _ref21.toLowerCase(),
          item_list_id: cartPage === null || cartPage === void 0 ? void 0 : cartPage.toLowerCase(),
          item_list_name: cartPage === null || cartPage === void 0 ? void 0 : cartPage.toLowerCase(),
          quantity: group.reduce(function (acc, item) {
            return acc + item.quantity;
          }, 0) || 1,
          price: cartPage !== EventValues.Slider ? priceFormatter(avgGroupPrice) : null,
          item_type: marketplacecart ? 'marketplace' : 'standard'
        };
      }
    }) || []
  };
  legacySendEvent({
    payload: {
      event: 'remove_from_cart',
      addtocart_type: 'default',
      ecommerce: ecommerce
    }
  });
};
export var setEmproveAddToCartData = function setEmproveAddToCartData(items, pageName) {
  try {
    var _items$5, _items$6;

    var currency = items !== null && items !== void 0 && items.length && (_items$5 = items[0]) !== null && _items$5 !== void 0 && _items$5.currency ? (_items$6 = items[0]) === null || _items$6 === void 0 ? void 0 : _items$6.currency : DEFAULT_CURRENCY_CODE;
    var itemGroups = groupBy(items || [], function (item) {
      return item.materialNumber;
    });
    var products = Object.values(itemGroups).map(function (group) {
      var _item$materialDescrip, _item$materialDescrip2, _item$materialDescrip3, _item$materialDescrip4;

      var item = group[0];
      return {
        id: ((item === null || item === void 0 ? void 0 : item.materialNumber) || EventValues.Empty).toLowerCase(),
        name: (item === null || item === void 0 ? void 0 : (_item$materialDescrip = item.materialDescription) === null || _item$materialDescrip === void 0 ? void 0 : (_item$materialDescrip2 = _item$materialDescrip.replace(regexForStripEncodingUnicode, '')) === null || _item$materialDescrip2 === void 0 ? void 0 : (_item$materialDescrip3 = _item$materialDescrip2.replace(regexForStripHTML, '')) === null || _item$materialDescrip3 === void 0 ? void 0 : (_item$materialDescrip4 = _item$materialDescrip3.replace(regexForStripSymbols, '')) === null || _item$materialDescrip4 === void 0 ? void 0 : _item$materialDescrip4.toLowerCase()) || EventValues.Empty,
        variant: ((item === null || item === void 0 ? void 0 : item.dossierMaterialNumber) || EventValues.Empty).toLowerCase(),
        brand: EventValues.SialBrand,
        quantity: 1,
        price: priceFormatter(item === null || item === void 0 ? void 0 : item.pricing),
        dimension91: 'standard'
      };
    });
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products: products,
      pageName: pageName,
      currency: currency
    });
    sendAddToCartAnalyticsEvent(true);
    return products ? products : [];
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
    return [];
  }
};
export var setQuickOrderAddToCartData = function setQuickOrderAddToCartData(pricingForMultipleProductInput, pageName, erpType) {
  try {
    var _pricingForMultiplePr, _pricingForMultiplePr2, _pricingForMultiplePr3, _pricingForMultiplePr4;

    var itemGroups = groupBy(pricingForMultipleProductInput || [], function (item) {
      return item.productNumber;
    });
    var currency = pricingForMultipleProductInput !== null && pricingForMultipleProductInput !== void 0 && pricingForMultipleProductInput.length && (_pricingForMultiplePr = pricingForMultipleProductInput[0]) !== null && _pricingForMultiplePr !== void 0 && (_pricingForMultiplePr2 = _pricingForMultiplePr.materialPricing[0]) !== null && _pricingForMultiplePr2 !== void 0 && _pricingForMultiplePr2.currency ? (_pricingForMultiplePr3 = pricingForMultipleProductInput[0]) === null || _pricingForMultiplePr3 === void 0 ? void 0 : (_pricingForMultiplePr4 = _pricingForMultiplePr3.materialPricing[0]) === null || _pricingForMultiplePr4 === void 0 ? void 0 : _pricingForMultiplePr4.currency : DEFAULT_CURRENCY_CODE;
    var products = Object.values(itemGroups).map(function (group) {
      var _ref22, _materialItem$materia, _materialItem$materia2, _materialItem$materia3, _materialItem$materia4, _ref23, _ref24;

      var item = group[0];
      var materialItem = item === null || item === void 0 ? void 0 : item.materialPricing[0];
      var avgGroupPrice = group.reduce(function (acc, item) {
        var _item$materialPricing;

        return ((item === null || item === void 0 ? void 0 : (_item$materialPricing = item.materialPricing[0]) === null || _item$materialPricing === void 0 ? void 0 : _item$materialPricing.price) || 0) + acc;
      }, 0) / group.length;
      return _objectSpread({
        id: (_ref22 = (materialItem === null || materialItem === void 0 ? void 0 : materialItem.product) || EventValues.Empty) === null || _ref22 === void 0 ? void 0 : _ref22.toLowerCase(),
        name: (materialItem === null || materialItem === void 0 ? void 0 : (_materialItem$materia = materialItem.materialDescription) === null || _materialItem$materia === void 0 ? void 0 : (_materialItem$materia2 = _materialItem$materia.replace(regexForStripEncodingUnicode, '')) === null || _materialItem$materia2 === void 0 ? void 0 : (_materialItem$materia3 = _materialItem$materia2.replace(regexForStripHTML, '')) === null || _materialItem$materia3 === void 0 ? void 0 : (_materialItem$materia4 = _materialItem$materia3.replace(regexForStripSymbols, '')) === null || _materialItem$materia4 === void 0 ? void 0 : _materialItem$materia4.toLowerCase()) || EventValues.Empty,
        variant: (_ref23 = (materialItem === null || materialItem === void 0 ? void 0 : materialItem.materialNumber) || EventValues.Empty) === null || _ref23 === void 0 ? void 0 : _ref23.toLowerCase(),
        brand: (_ref24 = (materialItem === null || materialItem === void 0 ? void 0 : materialItem.brand) || EventValues.Empty) === null || _ref24 === void 0 ? void 0 : _ref24.toLowerCase(),
        quantity: group.reduce(function (acc, item) {
          var _item$materialPricing2;

          return acc + (item === null || item === void 0 ? void 0 : (_item$materialPricing2 = item.materialPricing[0]) === null || _item$materialPricing2 === void 0 ? void 0 : _item$materialPricing2.quantity);
        }, 0) || 1,
        price: priceFormatter(avgGroupPrice),
        dimension91: (materialItem === null || materialItem === void 0 ? void 0 : materialItem.catalogType) === 'marketplace' ? 'marketplace' : 'standard'
      }, !showGTMTag && {
        erpType: erpType && mapProductErpType(erpType[materialItem === null || materialItem === void 0 ? void 0 : materialItem.materialNumber] || [])
      });
    });
    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products: products,
      pageName: pageName,
      currency: currency
    });
    sendAddToCartAnalyticsEvent();
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
  }
};
export var sendShoppingCartPageEvent = function sendShoppingCartPageEvent(_ref25) {
  var actionTitle = _ref25.actionTitle,
      gaId = _ref25.gaId,
      _ref25$clickType = _ref25.clickType,
      clickType = _ref25$clickType === void 0 ? 'shopping cart' : _ref25$clickType,
      clickAction = _ref25.clickAction,
      clickDetails = _ref25.clickDetails,
      _ref25$isMarketplaceC = _ref25.isMarketplaceCart,
      isMarketplaceCart = _ref25$isMarketplaceC === void 0 ? false : _ref25$isMarketplaceC,
      component = _ref25.component,
      elementType = _ref25.elementType,
      linkText = _ref25.linkText,
      linkUrl = _ref25.linkUrl;
  // GA4
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'shopping_cart_page_click',
      click_type: clickType || undefined,
      click_action: clickAction || actionTitle.toLowerCase() || undefined,
      click_details: clickDetails,
      shoppping_cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      component: component,
      element_type: elementType,
      link_text: linkText || clickDetails || actionTitle.toLowerCase() || undefined,
      link_url: linkUrl,
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0
    }
  });
};
export var sendProceedToCheckoutEvent = function sendProceedToCheckoutEvent(_ref26) {
  var actionTitle = _ref26.actionTitle,
      gaId = _ref26.gaId,
      isMarketplaceCart = _ref26.isMarketplaceCart,
      section = _ref26.section,
      component = _ref26.component,
      _ref26$switchCart = _ref26.switchCart,
      switchCart = _ref26$switchCart === void 0 ? false : _ref26$switchCart;
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      action: undefined,
      detail: "proceed to ".concat(isMarketplaceCart ? 'marketplace' : 'standard', " checkout"),
      section: section,
      component: component,
      element_type: 'button',
      element_text: 'checkout',
      link_url: undefined,
      core_event: 'yes',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      event: 'proceed_to_checkout',
      cart_type: isMarketplaceCart && !switchCart ? 'marketplace' : 'standard',
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0
    }
  });
};
export var sendBeginSaveCartEvent = function sendBeginSaveCartEvent(_ref27) {
  var actionTitle = _ref27.actionTitle,
      gaId = _ref27.gaId,
      isMarketplaceCart = _ref27.isMarketplaceCart;
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      action: undefined,
      detail: "proceed to save ".concat(isMarketplaceCart ? 'marketplace' : 'standard', " cart"),
      section: 'summary',
      component: 'right rail',
      element_type: 'button',
      element_text: 'save cart',
      link_url: undefined,
      core_event: 'yes',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
      event: 'begin_save_cart',
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ShoppingCartPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0
    }
  });
};
export var sendViewShoppingCartEvent = function sendViewShoppingCartEvent(_ref28, legacyUAFields) {
  var isMarketplaceCart = _ref28.isMarketplaceCart,
      detail = _ref28.detail,
      section = _ref28.section,
      component = _ref28.component,
      linkUrl = _ref28.linkUrl;
  var basePayload = {
    event: 'view_shopping_cart',
    action: undefined,
    detail: detail,
    section: section,
    component: component,
    element_type: 'button',
    element_text: 'view cart',
    link_url: linkUrl,
    core_event: 'yes',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    cart_type: isMarketplaceCart ? 'marketplace' : 'standard'
  };
  var buildPayload = legacyUAFields ? {
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: _objectSpread(_objectSpread({}, basePayload), legacyUAFields)
  } : {
    payload: basePayload
  };
  sendEvent(buildPayload);
};
/*
'checkout page: summary'
*/

export var sendCheckoutPageEvent = function sendCheckoutPageEvent(_ref29, isMarketplace, ga4payload) {
  var actionTitle = _ref29.actionTitle,
      gaId = _ref29.gaId;
  var basePayload = {
    event: (ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.event) || '',
    action: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.action,
    detail: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.detail,
    section: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.section,
    component: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.component,
    element_type: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.elementType,
    element_text: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.elementText,
    link_url: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.linkUrl,
    core_event: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.coreEvent,
    user_detail: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.userDetail,
    event_group: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.eventGroup,
    event_subgroup: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.eventSubGroup,
    cart_type: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.cartType
  };
  var legacyUAFields = {
    eventCategory: isMarketplace ? GTMMarketplaceEventCategory.MarketplaceCheckoutPage : GTMEventCategory.CheckoutPage,
    eventAction: isMarketplace ? GTMMarketplaceEventValues.SubmitMarketplaceOrder : actionTitle,
    eventLabel: gaId,
    eventInteractionType: 0
  };
  var buildPayload = ga4payload ? actionTitle ? {
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: _objectSpread(_objectSpread({}, basePayload), legacyUAFields)
  } : {
    payload: basePayload
  } : {
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: legacyUAFields
  };
  sendEvent(buildPayload);
};
export var sendCheckoutStayReturnEvent = function sendCheckoutStayReturnEvent(ga4payload, legacyUAFields) {
  var basePayload = {
    event: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.eventType,
    action: undefined,
    cart_type: ga4payload !== null && ga4payload !== void 0 && ga4payload.isMarketplace ? 'marketplace' : 'standard',
    component: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.component,
    detail: undefined,
    element_type: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.elementType,
    element_text: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.elementText,
    event_group: undefined,
    event_subgroup: undefined,
    core_event: 'yes',
    link_url: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.linkUrl,
    section: ga4payload === null || ga4payload === void 0 ? void 0 : ga4payload.section,
    user_detail: undefined
  };
  var buildPayload = legacyUAFields ? {
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: _objectSpread(_objectSpread({}, basePayload), legacyUAFields)
  } : {
    payload: basePayload
  };
  sendEvent(buildPayload);
};
export var sendCartClickEvent = function sendCartClickEvent(products, eventType, gaCookie) {
  var eventLabel = eventType === EventValues.Checkout ? gaCookie : products === null || products === void 0 ? void 0 : products.map(function (product) {
    var _product$material;

    return product === null || product === void 0 ? void 0 : (_product$material = product.material) === null || _product$material === void 0 ? void 0 : _product$material.number;
  }).join(' | ');
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.YourCart,
      eventAction: eventType,
      eventLabel: eventLabel,
      eventInteractionType: 0
    }
  });
};
export var sendCheckoutEvent = function sendCheckoutEvent(actionTitle, cartData) {
  var isB2BUser = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var userIsLoggedIn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var userErpType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var isBlueErpIntegrationEnabled = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

  try {
    if (!cartData) throw new Error();

    if (isTealium) {
      sendTealiumCartDataEvent({
        viewType: actionTitle,
        cartData: cartData,
        isB2BUser: isB2BUser,
        userIsLoggedIn: userIsLoggedIn,
        userErpType: userErpType,
        isBlueErpIntegrationEnabled: isBlueErpIntegrationEnabled
      });
    }

    if (showGTMTag) {
      var products = cartData.items || [];
      var currencyCode = cartData.currency;
      var cartType = cartData.cartType === 'marketplacecart' ? Dimension91.Marketplace : Dimension91.Standard;
      var productsArray = clubbingUpProducts(products, undefined, cartType) || [];

      if (actionTitle === '1') {
        // GA4 - Shopping cart (view_cart)
        legacySendEvent({
          payload: {
            event: 'view_cart',
            addtocart_type: 'default',
            cart_type: cartType,
            ecommerce: {
              currency: getCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE),
              items: productsArray.map(function (item) {
                var _item$quantity, _item$discount;

                return {
                  item_id: item.id,
                  item_name: item.name,
                  coupon: item.coupon,
                  item_brand: item.brand,
                  item_variant: item.variant,
                  price: priceFormatter(item === null || item === void 0 ? void 0 : item.price),
                  quantity: (_item$quantity = item.quantity) !== null && _item$quantity !== void 0 ? _item$quantity : 1,
                  discount: (_item$discount = item.discount) !== null && _item$discount !== void 0 ? _item$discount : 0,
                  item_type: cartType
                };
              })
            }
          }
        });
      }

      if (actionTitle === '2') {
        var _cartData$selectedPay, _cartData$contractNum, _cartData$shippingPre, _cartData$shippingPre2, _cartData$shippingPre3;

        var paymentMethod = mapPaymentMethod((_cartData$selectedPay = cartData.selectedPaymentMethod) !== null && _cartData$selectedPay !== void 0 ? _cartData$selectedPay : '', cartData.chargebackCodes.length > 0, (_cartData$contractNum = cartData.contractNumber) !== null && _cartData$contractNum !== void 0 ? _cartData$contractNum : '');
        var shippingMethodDescription = cartData === null || cartData === void 0 ? void 0 : (_cartData$shippingPre = cartData.shippingPreference) === null || _cartData$shippingPre === void 0 ? void 0 : (_cartData$shippingPre2 = _cartData$shippingPre.participantShippingMethods) === null || _cartData$shippingPre2 === void 0 ? void 0 : (_cartData$shippingPre3 = _cartData$shippingPre2.find(function (method) {
          var _cartData$shippingPre4;

          return (method === null || method === void 0 ? void 0 : method.shippingMethodCode) === (cartData === null || cartData === void 0 ? void 0 : (_cartData$shippingPre4 = cartData.shippingPreference) === null || _cartData$shippingPre4 === void 0 ? void 0 : _cartData$shippingPre4.shippingMethod);
        })) === null || _cartData$shippingPre3 === void 0 ? void 0 : _cartData$shippingPre3.description; // GA4 - Checkout page (begin_checkout)

        legacySendEvent({
          payload: {
            event: 'begin_checkout',
            addtocart_type: cartData.cartType === 'buyitnowcart' ? 'buy it now' : 'default',
            cart_type: cartType,
            payment_type: paymentMethod,
            shipping_tier: shippingMethodDescription,
            ecommerce: {
              currency: getCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE),
              items: productsArray.map(function (item) {
                var _item$quantity2, _item$discount2;

                return {
                  item_id: item.id,
                  item_name: item.name,
                  coupon: item.coupon,
                  item_brand: item.brand,
                  item_variant: item.variant,
                  price: priceFormatter(item === null || item === void 0 ? void 0 : item.price),
                  quantity: (_item$quantity2 = item.quantity) !== null && _item$quantity2 !== void 0 ? _item$quantity2 : 1,
                  discount: (_item$discount2 = item.discount) !== null && _item$discount2 !== void 0 ? _item$discount2 : 0,
                  item_type: cartType
                };
              })
            }
          }
        });
      } // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
      //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024


      legacySendEvent({
        eventType: GTMEventTypes.Checkout,
        payload: {
          eventCategory: GTMEventCategory.Checkout,
          eventAction: actionTitle,
          eventLabel: productsArray === null || productsArray === void 0 ? void 0 : productsArray.map(function (item) {
            return item.id;
          }).join('|'),
          eventInteractionType: 1,
          ecommerce: {
            currencyCode: getCurrencyCode(currencyCode || DEFAULT_CURRENCY_CODE),
            checkout: _objectSpread({
              actionField: _objectSpread({
                step: actionTitle
              }, actionTitle === '1' || actionTitle === '2' ? {
                option: cartData.cartType === 'marketplacecart' ? actionTitle === '1' ? 'marketplace' : cartData.selectedPaymentMethod === 'CC' ? 'marketplace cc' : 'marketplace po' : 'standard'
              } : {})
            }, actionTitle === '1' ? {
              products: productsArray
            } : '')
          }
        }
      });
    }
  } catch (error) {
    console.error('ERROR - Checkout Event ', error);
  }
};
export var sendAddToCartSideRailEvent = function sendAddToCartSideRailEvent(product, currency, pageName) {
  var _product$material2, _product$material3, _product$material4, _product$material5;

  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: GTMEventTypes.AddToCart,
      eventCategory: GTMEventCategory.AddToCart,
      eventAction: 'shopping cart - buy it again',
      eventLabel: product.material.id,
      eventInteractionType: 0,
      ecommerce: {
        currencyCode: currency ? getCurrencyCode(currency) : null,
        //currency passed in from parent component in shopping cart
        add: {
          actionField: {
            list: 'shopping cart'
          },
          products: [{
            id: "".concat(product.material.id),
            name: "".concat(product.material.name),
            brand: "".concat(product.material.brand.name),
            quantity: 1,
            price: 1,
            //placeholder as requested to not break structure - Should be replaced with priceFormatter() when actual pricing data is passed.
            dimension91: 'standard'
          }]
        }
      }
    }
  }); // GA4 - Add To Cart

  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: {
        currency: currency ? getCurrencyCode(currency) : null,
        //currency passed in from parent component in shopping cart
        value: 1,
        items: [{
          item_id: product === null || product === void 0 ? void 0 : (_product$material2 = product.material) === null || _product$material2 === void 0 ? void 0 : _product$material2.id,
          item_name: product === null || product === void 0 ? void 0 : (_product$material3 = product.material) === null || _product$material3 === void 0 ? void 0 : _product$material3.name,
          item_brand: product === null || product === void 0 ? void 0 : (_product$material4 = product.material) === null || _product$material4 === void 0 ? void 0 : _product$material4.brand,
          discount: 0,
          item_list_id: pageName === null || pageName === void 0 ? void 0 : pageName.toLowerCase(),
          item_list_name: pageName === null || pageName === void 0 ? void 0 : pageName.toLowerCase(),
          item_variant: product === null || product === void 0 ? void 0 : (_product$material5 = product.material) === null || _product$material5 === void 0 ? void 0 : _product$material5.number,
          price: 1,
          //placeholder as requested to not break structure - Should be replaced with priceFormatter() when actual pricing data is passed.
          item_type: 'standard',
          quantity: 1
        }]
      }
    }
  });
};
export var setAddToCartData = function setAddToCartData(values, materialPricing, pageName, gaMapProducts, erpType, userType, bundle) {
  try {
    var products;
    var currency = getCurrencyCodeFromProduct(materialPricing);

    switch (pageName) {
      case AddToCartPagesEnum.CustomList:
      case AddToCartPagesEnum.QuoteDetails:
      case AddToCartPagesEnum.ShoppingCart:
      case AddToCartPagesEnum.OrderDetails:
      case AddToCartPagesEnum.OrderDetailsOrderSummary:
      case AddToCartPagesEnum.SavedCart:
      case AddToCartPagesEnum.QuickOrder:
      case AddToCartPagesEnum.QuickOrderQuotesModal:
      case AddToCartPagesEnum.HeaderSearch:
      case AddToCartPagesEnum.BuyItAgainPage:
      case AddToCartPagesEnum.PromoBundle:
      case AddToCartPagesEnum.AccountDashboardQuote:
      case AddToCartPagesEnum.AccountDashboardOrder:
        products = (gaMapProducts || setMaterialData)(values, materialPricing, pageName, bundle);
        break;

      default:
        products = (gaMapProducts || setPaClipData)(values, materialPricing);
        break;
    }

    if (products && products.length) {
      var result = products.map(function (k) {
        var cartItem = values.find(function (v) {
          var _v$materialNumber;

          return k.variant === ((_v$materialNumber = v.materialNumber) === null || _v$materialNumber === void 0 ? void 0 : _v$materialNumber.toLowerCase());
        });
        return _objectSpread(_objectSpread({}, k), {}, {
          dimension91: materialPricing !== null && materialPricing !== void 0 && materialPricing.length && !!(cartItem !== null && cartItem !== void 0 && cartItem.marketplaceOfferId) ? Dimension91.Marketplace : Dimension91.Standard
        }, !showGTMTag && {
          erpType: mapProductErpType(getErpTypeParam(erpType, cartItem) || [], userType, pageName)
        });
      });
      sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
        products: result,
        pageName: pageName,
        currency: currency
      });
    }
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
  }
};
export var sendBundleKitAddToCartEvent = function sendBundleKitAddToCartEvent(promoBundlePricing, bundleId) {
  var _bundleItems$;

  var quantity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var bundleItems = (promoBundlePricing === null || promoBundlePricing === void 0 ? void 0 : promoBundlePricing.bundleItems) || [];
  var productsArray = bundleItems.map(function (item) {
    var _item$materialId, _item$materialName, _item$materialNumber, _item$brand3;

    return {
      id: (item === null || item === void 0 ? void 0 : (_item$materialId = item.materialId) === null || _item$materialId === void 0 ? void 0 : _item$materialId.toLowerCase()) || EventValues.Empty,
      name: (item === null || item === void 0 ? void 0 : (_item$materialName = item.materialName) === null || _item$materialName === void 0 ? void 0 : _item$materialName.toLowerCase().replace(regexForStripEncodingUnicode, '').replace(regexForStripHTML, '').replace(regexForStripSpecialChars, '')) || EventValues.Empty,
      variant: (item === null || item === void 0 ? void 0 : (_item$materialNumber = item.materialNumber) === null || _item$materialNumber === void 0 ? void 0 : _item$materialNumber.toLowerCase()) || EventValues.Empty,
      brand: (item === null || item === void 0 ? void 0 : (_item$brand3 = item.brand) === null || _item$brand3 === void 0 ? void 0 : _item$brand3.toLowerCase()) || EventValues.Empty,
      quantity: quantity * (item === null || item === void 0 ? void 0 : item.quantity) || 1,
      price: priceFormatter(item === null || item === void 0 ? void 0 : item.yourPrice),
      dimension91: 'standard',
      coupon: bundleId
    };
  });
  var addtocartPayload = {
    productIds: bundleItems.map(function (item) {
      return item.materialId;
    }).join('|') || EventValues.Empty,
    actionTitle: 'promo bundle',
    ecommerce: {
      currencyCode: getCurrencyCode(((_bundleItems$ = bundleItems[0]) === null || _bundleItems$ === void 0 ? void 0 : _bundleItems$.currency) || DEFAULT_CURRENCY_CODE),
      add: {
        actionField: {
          list: 'promo bundle'
        },
        products: productsArray,
        dimension91: 'standard'
      }
    }
  };

  if (isTealium) {
    sendAddItemsToCartEvent({
      sessionStorageKey: ADD_TO_CART_GA_DATA_STORAGE_KEY
    });
  }

  if (showGTMTag) {
    uaConfiguratorAddToCart(addtocartPayload); // GA4 - Add To Cart

    legacySendEvent({
      payload: {
        event: 'add_to_cart',
        addtocart_type: 'default',
        ecommerce: {
          currency: getCurrencyCode((promoBundlePricing === null || promoBundlePricing === void 0 ? void 0 : promoBundlePricing.currency) || DEFAULT_CURRENCY_CODE),
          value: bundleItems.reduce(function (acc, bundle) {
            var _bundle$quantity;

            return (Number(bundle.yourPrice) || 1) * ((_bundle$quantity = bundle.quantity) !== null && _bundle$quantity !== void 0 ? _bundle$quantity : 1) + acc;
          }, 0),
          items: bundleItems === null || bundleItems === void 0 ? void 0 : bundleItems.map(function (bundle) {
            var _bundle$discount, _bundle$quantity2;

            return {
              item_id: bundle.materialId,
              item_name: bundle.materialName,
              discount: (_bundle$discount = bundle.discount) !== null && _bundle$discount !== void 0 ? _bundle$discount : 0,
              item_brand: bundle.brand,
              item_list_id: AddToCartPagesEnum.PromoBundle,
              item_list_name: AddToCartPagesEnum.PromoBundle,
              item_variant: bundle.materialNumber,
              price: priceFormatter(bundle === null || bundle === void 0 ? void 0 : bundle.yourPrice),
              item_type: 'standard',
              quantity: (_bundle$quantity2 = bundle.quantity) !== null && _bundle$quantity2 !== void 0 ? _bundle$quantity2 : 1
            };
          })
        }
      }
    });
  }
};
export var sendB2BBuyNowModalTransferCart = function sendB2BBuyNowModalTransferCart() {
  var _payload$ecommerce, _payload$ecommerce$pu, _payload$ecommerce2, _payload$ecommerce2$p, _payload$ecommerce2$p2, _payload$ecommerce3, _payload$ecommerce3$p, _payload$ecommerce3$p2, _payload$ecommerce4, _payload$ecommerce4$p, _payload$ecommerce4$p2, _payload$ecommerce5, _payload$ecommerce5$p, _payload$ecommerce5$p2, _payload$ecommerce6, _payload$ecommerce7, _payload$ecommerce7$p, _payload$ecommerce7$p2;

  var payload = sessionStorage.getItem(CART_DATA_STORAGE_KEY);
  var subTotal = payload === null || payload === void 0 ? void 0 : (_payload$ecommerce = payload.ecommerce) === null || _payload$ecommerce === void 0 ? void 0 : (_payload$ecommerce$pu = _payload$ecommerce.purchase) === null || _payload$ecommerce$pu === void 0 ? void 0 : _payload$ecommerce$pu.products.reduce(function (acc, item) {
    return (Number(item.price) * item.quantity || 0) + acc;
  }, 0); // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024

  legacySendEvent({
    payload: {
      event: 'purchase',
      addtocart_type: 'buy it now',
      order_type: 'b2b-standard',
      deep_order_id: undefined,
      order_participant_id: (payload === null || payload === void 0 ? void 0 : payload.purchaseParticipantId) || undefined,
      order_sold_to: (payload === null || payload === void 0 ? void 0 : payload.purchaseSoldToNumber) || undefined,
      order_bill_to: (payload === null || payload === void 0 ? void 0 : payload.purchaseBillToNumber) || undefined,
      order_ship_to: (payload === null || payload === void 0 ? void 0 : payload.purchaseShipToNumber) || undefined,
      payment_type: 'b2b',
      shipping_tier: (payload === null || payload === void 0 ? void 0 : (_payload$ecommerce2 = payload.ecommerce) === null || _payload$ecommerce2 === void 0 ? void 0 : (_payload$ecommerce2$p = _payload$ecommerce2.purchase) === null || _payload$ecommerce2$p === void 0 ? void 0 : (_payload$ecommerce2$p2 = _payload$ecommerce2$p.actionField) === null || _payload$ecommerce2$p2 === void 0 ? void 0 : _payload$ecommerce2$p2.shippingMethod) || undefined,
      ecommerce: {
        transaction_id: (payload === null || payload === void 0 ? void 0 : payload.eventAction) || undefined,
        value: subTotal || 1,
        tax: priceFormatter(payload === null || payload === void 0 ? void 0 : (_payload$ecommerce3 = payload.ecommerce) === null || _payload$ecommerce3 === void 0 ? void 0 : (_payload$ecommerce3$p = _payload$ecommerce3.purchase) === null || _payload$ecommerce3$p === void 0 ? void 0 : (_payload$ecommerce3$p2 = _payload$ecommerce3$p.actionField) === null || _payload$ecommerce3$p2 === void 0 ? void 0 : _payload$ecommerce3$p2.tax),
        shipping: priceFormatter(payload === null || payload === void 0 ? void 0 : (_payload$ecommerce4 = payload.ecommerce) === null || _payload$ecommerce4 === void 0 ? void 0 : (_payload$ecommerce4$p = _payload$ecommerce4.purchase) === null || _payload$ecommerce4$p === void 0 ? void 0 : (_payload$ecommerce4$p2 = _payload$ecommerce4$p.actionField) === null || _payload$ecommerce4$p2 === void 0 ? void 0 : _payload$ecommerce4$p2.shipping),
        coupon: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce5 = payload.ecommerce) === null || _payload$ecommerce5 === void 0 ? void 0 : (_payload$ecommerce5$p = _payload$ecommerce5.purchase) === null || _payload$ecommerce5$p === void 0 ? void 0 : (_payload$ecommerce5$p2 = _payload$ecommerce5$p.actionField) === null || _payload$ecommerce5$p2 === void 0 ? void 0 : _payload$ecommerce5$p2.coupon,
        currency: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce6 = payload.ecommerce) === null || _payload$ecommerce6 === void 0 ? void 0 : _payload$ecommerce6.currencyCode,
        items: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce7 = payload.ecommerce) === null || _payload$ecommerce7 === void 0 ? void 0 : (_payload$ecommerce7$p = _payload$ecommerce7.purchase) === null || _payload$ecommerce7$p === void 0 ? void 0 : (_payload$ecommerce7$p2 = _payload$ecommerce7$p.products) === null || _payload$ecommerce7$p2 === void 0 ? void 0 : _payload$ecommerce7$p2.map(function (product) {
          var _product$discount2, _product$quantity4;

          return {
            item_id: product.id,
            item_name: product.name,
            coupon: product.coupon || undefined,
            discount: (_product$discount2 = product.discount) !== null && _product$discount2 !== void 0 ? _product$discount2 : 0,
            item_brand: product.brand,
            item_variant: product.variant,
            price: priceFormatter(product === null || product === void 0 ? void 0 : product.price),
            item_type: product.dimension91,
            quantity: (_product$quantity4 = product.quantity) !== null && _product$quantity4 !== void 0 ? _product$quantity4 : 1
          };
        })
      }
    }
  });
};
export var sendB2BBuyNowModalAddToCart = function sendB2BBuyNowModalAddToCart() {
  var _payload$ecommerce8, _payload$ecommerce9, _payload$ecommerce9$p, _payload$ecommerce9$p2, _payload$ecommerce10, _payload$ecommerce10$, _payload$ecommerce10$2;

  var payload = sessionStorage.getItem(CART_DATA_STORAGE_KEY); // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024

  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: {
        currency: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce8 = payload.ecommerce) === null || _payload$ecommerce8 === void 0 ? void 0 : _payload$ecommerce8.currencyCode,
        value: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce9 = payload.ecommerce) === null || _payload$ecommerce9 === void 0 ? void 0 : (_payload$ecommerce9$p = _payload$ecommerce9.purchase) === null || _payload$ecommerce9$p === void 0 ? void 0 : (_payload$ecommerce9$p2 = _payload$ecommerce9$p.products) === null || _payload$ecommerce9$p2 === void 0 ? void 0 : _payload$ecommerce9$p2.reduce(function (acc, product) {
          var _product$quantity5;

          return (Number(product.price) || 1) * ((_product$quantity5 = product.quantity) !== null && _product$quantity5 !== void 0 ? _product$quantity5 : 1) + acc;
        }, 0),
        items: payload === null || payload === void 0 ? void 0 : (_payload$ecommerce10 = payload.ecommerce) === null || _payload$ecommerce10 === void 0 ? void 0 : (_payload$ecommerce10$ = _payload$ecommerce10.purchase) === null || _payload$ecommerce10$ === void 0 ? void 0 : (_payload$ecommerce10$2 = _payload$ecommerce10$.products) === null || _payload$ecommerce10$2 === void 0 ? void 0 : _payload$ecommerce10$2.map(function (product) {
          var _product$discount3, _product$quantity6;

          return {
            item_id: product.id,
            item_name: product.name,
            item_list_id: 'buy it now',
            item_list_name: 'buy it now',
            discount: (_product$discount3 = product.discount) !== null && _product$discount3 !== void 0 ? _product$discount3 : 0,
            item_brand: product.brand,
            item_variant: product.variant,
            price: priceFormatter(product === null || product === void 0 ? void 0 : product.price),
            item_type: product.dimension91,
            quantity: (_product$quantity6 = product.quantity) !== null && _product$quantity6 !== void 0 ? _product$quantity6 : 1
          };
        })
      }
    }
  });
};
export var sendAffiliationCheckoutClickEvent = function sendAffiliationCheckoutClickEvent(participantId) {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: AffiliationCheckoutEventActionEnum.AffiliationPage,
      eventAction: AffiliationCheckoutEventActionEnum.CompleteCheckout,
      eventLabel: participantId,
      eventInteractionType: 0
    }
  });
};